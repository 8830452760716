












import { defineComponent } from '@nuxtjs/composition-api'
import { ConfettiBuzzerForm } from '~/components/ConfettiBuzzerForm.vue'
import { ConfettiNotice } from '~/components/ConfettiNotice.vue'
import ConfettiLogo from '~/components/ConfettiLogo.vue'

export const ConfettiPageWrapper = defineComponent({
  components: {
    ConfettiLogo,
    ConfettiNotice,
    ConfettiBuzzerForm,
  },
})

export default ConfettiPageWrapper
