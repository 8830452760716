






import { defineComponent } from '@nuxtjs/composition-api'

export const ConfettiModalContent = defineComponent({})

export default ConfettiModalContent
