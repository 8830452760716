






import { defineComponent } from '@nuxtjs/composition-api'
import { ConfettiPageWrapper } from '~/components/ConfettiPageWrapper.vue'
import { ConfettiBuzzerForm } from '~/components/ConfettiBuzzerForm.vue'
import { ConfettiModal } from '~/components/ConfettiModal.vue'
import { ConfettiModalContent } from '~/components/ConfettiModalContent.vue'
import ConfettiSadCloudIcon from '~/assets/ConfettiSadCloudIcon.svg?inline'

export default defineComponent({
  components: {
    ConfettiPageWrapper,
    ConfettiBuzzerForm,
    ConfettiModal,
    ConfettiModalContent,
    ConfettiSadCloudIcon,
  },
})
