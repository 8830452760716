// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalContent_aZCRC{background:#fff;border-radius:3rem;box-shadow:var(--static-high-base-shadow);margin:2rem;padding:3rem;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "modalContent_aZCRC"
};
module.exports = ___CSS_LOADER_EXPORT___;
