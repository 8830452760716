

















import { defineComponent, ref } from '@nuxtjs/composition-api'

export const ConfettiModal = defineComponent({
  head: {
    htmlAttrs: {
      style: 'overflow: hidden;',
    },
  },
  setup(_, { emit }) {
    const modal = ref<HTMLElement>()
    const close = () => emit('close')
    const open = () => {
      modal.value?.focus()
      emit('open')
    }

    return { close, open, modal }
  },
})

export default ConfettiModal
